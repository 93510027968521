<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">

        <!-- 標題 -->
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary">
            忘記密碼
          </h2>
        </b-link>

        <!-- 小標題 -->
        <b-card-title class="mb-1">
          忘記密碼? 🔒
        </b-card-title>

        <!-- 小標題說明 -->
        <b-card-text class="mb-2">
          輸入您的電子郵件，我們將向您發送重置密碼的說明
        </b-card-text>

        <!-- 忘記密碼填寫表單 -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="電子信箱"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="電子信箱"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              發送重置連結
            </b-button>
          </b-form>
        </validation-observer>

        <!-- 導向按鈕 -->
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> 返回登入
          </b-link>
        </b-card-text>

      </b-card>
    </div>

    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      sitekey="6LeKGO0aAAAAAFoXqpakoMDb8JgP8lmjPa7xwx09"
      @verify="setForgotPW"
    />
  </div>
</template>

<script>
// API
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email } from '@validations'
import { useToast } from 'vue-toastification/composition'
import axios from '@axios'
import { ref } from '@vue/composition-api'
// UI
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'

// Components
import VueRecaptcha from 'vue-recaptcha'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    // Locale,

    VueRecaptcha,
  },
  data() {
    return {
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(isFinish => {
        if (isFinish) {
          this.$refs.recaptcha.execute()
        }
      })
    },
  },

  setup() {
    localize('tw')
    const toast = useToast()

    const userEmail = ref('')

    const setForgotPW = recaptchaToken => axios('/api/forgot', {
      method: 'POST',
      data: {
        email: userEmail.value,
        rc_token: recaptchaToken,
      },
    })
      .then(response => {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: response.data.message,
            icon: response.data.success ? 'success' : 'InfoIcon',
            variant: response.data.success ? 'success' : 'danger',
          },
        })
      })
    // }

    return {
      userEmail,

      setForgotPW,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper{
  min-height: 75vh;
}
</style>
